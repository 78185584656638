import { createContext, createMemo, JSX, useContext } from "solid-js";
import c from "class-c";
import { PropsFor } from "solid-u";

import Logo from "@repo/ui/branding/Logo";
import Link from "@repo/ui/navigation/Link";
import UString from "@repo/utils/UString";
import AppRouter from "@repo/utils-solid/AppRouter";

import styles from "./DashboardLayout.module.scss";

declare namespace DashboardLayout {
  type Props = {
    tabs: JSX.Element;
    baseUrl?: string;
    children: JSX.Element;
    propsFor?: PropsFor<{ logo?: Logo.Props }>;
  };
}

function DashboardLayout({
  baseUrl = "",
  tabs,
  propsFor: _propsFor,
  children,
}: D<DashboardLayout.Props>) {
  const propsFor = PropsFor.createHandler(() => _propsFor);
  return (
    <div class={styles["dashboard-layout"]}>
      <nav>
        <div>
          <Logo
            icon
            word
            {...propsFor("logo")}
            class={c`${styles.logo} text:heading-3++ mb-8 ${propsFor("logo").class}`}
          />
          <Context.Provider value={{ baseUrl }}>{tabs}</Context.Provider>
        </div>
      </nav>
      <main>{children}</main>
    </div>
  );
}
export default DashboardLayout;

export declare namespace Tab {
  type Props = {
    class?: string;
    path: string;
    long: string;
    icon: string;
    short: string;
  };
}

export function Tab({
  class: className,
  path,
  long,
  icon,
  short,
}: D<Tab.Props>) {
  const context = useContext(Context);
  if (!context)
    throw new Error("Must render Tab inside DashboardLayout tabs prop.");

  const resolvedPath = createMemo(
    () => UString.trim.end(`${context.baseUrl}${path}`, "/") || "/"
  );
  const active = createMemo(() =>
    path === "/"
      ? AppRouter.pathname === resolvedPath()
      : AppRouter.pathname.startsWith(resolvedPath())
  );

  return (
    <Link
      href={resolvedPath()}
      class={c`
        ${styles["tab"]}
        ${active() && styles.active}
        ${className}
      `}
      variant="container"
    >
      <div class={`${styles.desktop} text:large ${active() && "text:++"}`}>
        {long}
      </div>
      <div class={styles.mobile}>
        <div class="icon:medium mb-0.25">{icon}</div>
        <div class="text:mini">{short}</div>
      </div>
    </Link>
  );
}

const Context = createContext<{ baseUrl: string }>();
